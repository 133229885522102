import dynamic from 'next/dynamic';

import type { Card as CardType } from '../components/Card';
import type { Image as ImageType } from '../components/Image';
import type { RichText as RichTextType } from '../components/RichText';
import type { Title as TitleType } from '../components/Title';
import type { Video as VideoType } from '../components/Video';

const Image = dynamic(() =>
  import('../components/Image').then((mod) => mod.Image),
) as typeof ImageType;
const RichText = dynamic(() =>
  import('../components/RichText').then((mod) => mod.RichText),
) as typeof RichTextType;
const Title = dynamic(() =>
  import('../components/Title').then((mod) => mod.Title),
) as typeof TitleType;
const Card = dynamic(() =>
  import('../components/Card').then((mod) => mod.Card),
) as typeof CardType;
const Video = dynamic(() =>
  import('../components/Video').then((mod) => mod.Video),
) as typeof VideoType;

const Components = {
  image: Image,
  rich_text: RichText,
  title: Title,
  card: Card,
  video: Video,
};

export const DynamicSection = ({ components }) => {
  return components?.length
    ? components?.map((component) => {
        if (
          !!component &&
          typeof Components[component?.component] !== 'undefined'
        ) {
          const Component = Components[component?.component];
          return <Component key={component?._uid} {...component} />;
        }

        return null;
      })
    : null;
};
